export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        subjects :'', 
        subject :'', 
    },
    
    getters : {
        subjects: (state )=> state.subjects,
        subject: (state )=> state.subject,
    },


    mutations : { 
        setsubjects :(state,subjects ) => (state.subjects = subjects), 
        setsubject :(state,subject ) => (state.subject = subject), 
    },

    actions : {

        async create_subject( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_subject')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/subjects/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsubjects', response.data.res)
                toast.success("Subject Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_subjects( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_subjects')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/subjects?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsubjects', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_subject( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_subject' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/subjects/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsubject', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_subject( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_subject' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/subjects/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsubjects', response.data.res)
                toast.success("Subject Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_subject_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_subject_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/entrant/subjects/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setsubjects', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Subject Activated Successfully")
                        } else {
                            toast.success("Subject Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_subject( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_subject' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/subjects/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsubjects', response.data.res)
                toast.success("Subject Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}
export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        scholarships :'', 
        scholarship :'', 
    },
    
    getters : {
        scholarships: (state )=> state.scholarships,
        scholarship: (state )=> state.scholarship,
    },


    mutations : { 
        setscholarships :(state,scholarships ) => (state.scholarships = scholarships), 
        setscholarship :(state,scholarship ) => (state.scholarship = scholarship), 
    },

    actions : {

        async create_scholarship( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_scholarship')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/scholarships/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setscholarships', response.data.res)
                toast.success("Scholarship Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_scholarships( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_scholarships')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/scholarships?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setscholarships', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_scholarship( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_scholarship' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/scholarships/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setscholarship', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_scholarship( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_scholarship' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/scholarships/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setscholarships', response.data.res)
                toast.success("Scholarship Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_scholarship_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_scholarship_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/entrant/scholarships/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setscholarships', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Scholarship Activated Successfully")
                        } else {
                            toast.success("Scholarship Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_scholarship( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_scholarship' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/scholarships/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setscholarships', response.data.res)
                toast.success("Scholarship Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}
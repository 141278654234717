export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        library_categories :'', 
        library_category :'', 
        library_items :'', 
        library_item :'', 
    },
    
    getters : {
        library_categories: (state )=> state.library_categories,
        library_category: (state )=> state.library_category,
        library_items: (state )=> state.library_items,
        library_item: (state )=> state.library_item,
    },


    mutations : { 
        setlibrary_categories :(state,library_categories ) => (state.library_categories = library_categories), 
        setlibrary_category :(state,library_category ) => (state.library_category = library_category), 
         setlibrary_items :(state,library_items ) => (state.library_items = library_items), 
        setlibrary_item :(state,library_item ) => (state.library_item = library_item), 
    },

    actions : {
        // ==================================== Categories ==================================
        async create_library_category( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_library_category')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/library_categories/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlibrary_categories', response.data.res)
                toast.success("Category Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_library_categories( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_library_categories')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/library_categories?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlibrary_categories', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_library_category( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_library_category' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/library_categories/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlibrary_category', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_library_category( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_library_category' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/library_categories/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlibrary_categories', response.data.res)
                toast.success("Category Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_library_category_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_library_category_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/entrant/library_categories/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setlibrary_categories', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Category Activated Successfully")
                        } else {
                            toast.success("Category Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_library_category( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_library_category' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/library_categories/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlibrary_categories', response.data.res)
                toast.success("Category Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        // ==================================== Resources ==================================
        async create_library_item( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_library_item')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/library_items/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlibrary_items', response.data.res)
                toast.success("Resource Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_library_items( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_library_items')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/library_items?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlibrary_items', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_library_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_library_item' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/library_items/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlibrary_item', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_library_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_library_item' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/library_items/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlibrary_items', response.data.res)
                toast.success("Resource Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_item_image_resource( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_item_image_resource' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/library_items/update_item_image_resource/"+ id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                toast.success("Resource File Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_library_item_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_library_item_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/entrant/library_items/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setlibrary_items', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Resource Activated Successfully")
                        } else {
                            toast.success("Resource Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_library_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_library_item' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/library_items/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlibrary_items', response.data.res)
                toast.success("Resource Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}
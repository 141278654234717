import store from '@/store'

const authGuards = (to, from, next) => {
    if(localStorage.getItem('educanToken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.id ){
      if (store.getters.authuser.role == 'admin' || store.getters.authuser.role == 'entrant') {
        return next()
      } else {
        return next({ name: 'login' })
      }
    }else{
        return next({ name: 'login' })
    }
};


let routes = [{
  path: "/",
  props: true,
  beforeEnter: authGuards,
  component: () => import("@/views/app/main.vue"),
  children: [
    // ============================================== Dashboard ============================================
    {
      path: "/",
      name: 'dashboard',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/dashboard/main.vue"),
      meta: {
        title: 'Dashboard',
      },
    },


    // ============================================= Education Settings ===================================
    {
      path: "/education_setting/",
      name: 'education_setting',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/education_setting/main.vue"),
      children: [
        {
          path: "curricula",
          name: 'curricula',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/education_setting/curricula.vue"),
          meta: {
            title: 'Curricula',
          },
        },

        {
          path: "levels",
          name: 'levels',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/education_setting/levels.vue"),
          meta: {
            title: 'Levels',
          },
        },

        {
          path: "classes",
          name: 'classes',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/education_setting/classes.vue"),
          meta: {
            title: 'Classes',
          },
        },

        {
          path: "subjects",
          name: 'subjects',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/education_setting/subjects.vue"),
          meta: {
            title: 'Subjects',
          },
        },

        {
          path: "curriculum_classes",
          name: 'curriculum_classes',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/education_setting/curriculum_classes.vue"),
          meta: {
            title: 'Curriculum Classes',
          },
        },

        {
          path: "topics",
          name: 'topics',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/education_setting/topics.vue"),
          meta: {
            title: 'Subject Topics',
          },
        },

        {
          path: "sub_topics",
          name: 'sub_topics',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/education_setting/sub_topics.vue"),
          meta: {
            title: 'Subject Sub Topics',
          },
        },
      ]
    },



    // ====================================== Library =============================================
    {
      path: "/library/",
      name: 'library',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/library/main.vue"),
      children: [
        {
          path: "library_categories",
          name: 'library_categories',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/library/categories.vue"),
          meta: {
            title: 'Library Categories',
          },
        },

        {
          path: "library_resources",
          name: 'library_resources',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/library/resources.vue"),
          meta: {
            title: 'Library Resources',
          },
        },

        {
          path: "create_resource",
          name: 'create_resource',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/library/create_resource.vue"),
          meta: {
            title: 'Create Library Resource',
          },
        },

        {
          path: "update_resource/:id",
          name: 'update_resource',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/library/update_resource.vue"),
          meta: {
            title: 'Update Library Resource',
          },
        },
      ]
    },


    // =================================== Teacher Resources =====================================
    {
      path: "/teacher_resources/",
      name: 'teacher_resources',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/teacher_resources/main.vue"),
      children: [
        {
          path: "teacher_resources_categories",
          name: 'teacher_resources_categories',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/teacher_resources/categories.vue"),
          meta: {
            title: 'Teacher Resource Categories',
          },
        },

        {
          path: "all_teacher_resources",
          name: 'all_teacher_resources',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/teacher_resources/resources.vue"),
          meta: {
            title: 'Teacher Resources',
          },
        },

        {
          path: "create_teacher_resource",
          name: 'create_teacher_resource',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/teacher_resources/create_resource.vue"),
          meta: {
            title: 'Create Teacher Resource',
          },
        },

        {
          path: "update_teacher_resource/:id",
          name: 'update_teacher_resource',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/teacher_resources/update_resource.vue"),
          meta: {
            title: 'Update Teacher Resource',
          },
        },
      ]
    },


    {
      path: "adverts",
      name: 'adverts',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/adverts/adverts.vue"),
      meta: {
        title: 'Adverts',
      },
    },

    {
      path: "impacts",
      name: 'impacts',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/impacts/impacts.vue"),
      meta: {
        title: 'Our Impact',
      },
    },

    {
      path: "scholarships",
      name: 'scholarships',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/scholarships/scholarships.vue"),
      meta: {
        title: 'Scholarships',
      },
    },

    {
      path: "contests",
      name: '',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/contests/main.vue"),
      children: [
        {
          path: "",
          name: 'contests',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/contests/contests.vue"),
          meta: {
            title: 'Contests',
          },
        },
        {
          path: ":id",
          name: 'contest_details',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/contests/details.vue"),
          meta: {
            title: 'Contest Details',
          },
        }
      ]
    },
        

    // ===================================== Educan Shop =================================
    {
      path: "/shop/",
      name: 'shop',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/shop/main.vue"),
      children: [
        {
          path: "shop_categories",
          name: 'shop_categories',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/shop/categories.vue"),
          meta: {
            title: 'Shop Categories',
          },
        },
      ]
    },


    // ===================================== Delivery Areas =================================
    {
      path: "/delivery_areas/",
      name: 'delivery_areas',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/delivery_areas/main.vue"),
      children: [
        {
          path: "areas",
          name: 'areas',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/delivery_areas/areas.vue"),
          meta: {
            title: 'Delivery Areas',
          },
        },
      ]
    },


    // ===================================== User Account Settings =================================
    {
      path: "/account",
      name: 'account',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/account/main.vue"),
      children: [
        {
          path: "/profile",
          name: 'profile',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/account/profile.vue"),
          meta: {
            title: 'Profile',
          },
        },
      ]
    },


    {
      path: "/notifications",
      name: '',
      props: true,
      beforeEnter: authGuards,
      component: () => import("@/views/app/notifications/main.vue"),
      children: [
        {
          path: "",
          name: 'notifications',
          props: true,
          beforeEnter: authGuards,
          component: () => import("@/views/app/notifications/notifications.vue"),
          meta: {
            title: 'notifications',
          },
        },
      ]
    },

  ]
}]
export default routes;
export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        contests :'', 
        contest: '', 
        schedules :'', 
        schedule :'', 
    },
    
    getters : {
        contests: (state )=> state.contests,
        contest: (state) => state.contest,
        schedules: (state )=> state.schedules,
        schedule: (state )=> state.schedule,
    },


    mutations : { 
        setcontests :(state,contests ) => (state.contests = contests), 
        setcontest: (state, contest) => (state.contest = contest), 
        setschedules :(state,schedules ) => (state.schedules = schedules), 
        setschedule :(state,schedule ) => (state.schedule = schedule), 
    },

    actions : {

        async create_contest( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_contest')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/contests/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontests', response.data.res)
                toast.success("Contest Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_contests( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_contests')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/contests?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontests', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_contest( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_contest' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/contests/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_contest( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_contest' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/contests/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontests', response.data.res)
                toast.success("Contest Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_contest_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_contest_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/entrant/contests/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setcontests', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Contest Activated Successfully")
                        } else {
                            toast.success("Contest Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_contest( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_contest' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/contests/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcontests', response.data.res)
                toast.success("Contest Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },





        // ============================ schedules ============================================
        async create_schedule( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_schedule')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/contests/schedules/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setschedules', response.data.res)
                toast.success("Schedule Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_schedules( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_schedules')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/contests/schedules?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setschedules', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_schedule( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_schedule' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/contests/schedules/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setschedule', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_schedule( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_schedule' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/contests/schedules/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setschedules', response.data.res)
                toast.success("Schedule Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_schedule_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_schedule_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/entrant/contests/schedules/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setschedules', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Schedule Activated Successfully")
                        } else {
                            toast.success("Schedule Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_schedule( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_schedule' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/contests/schedules/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setschedules', response.data.res)
                toast.success("Schedule Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}
export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        tr_resource_categories :'', 
        tr_resource_category :'', 
        tr_resources :'', 
        tr_resource :'', 
    },
    
    getters : {
        tr_resource_categories: (state )=> state.tr_resource_categories,
        tr_resource_category: (state )=> state.tr_resource_category,
        tr_resources: (state )=> state.tr_resources,
        tr_resource: (state )=> state.tr_resource,
    },


    mutations : { 
        settr_resource_categories :(state,tr_resource_categories ) => (state.tr_resource_categories = tr_resource_categories), 
        settr_resource_category :(state,tr_resource_category ) => (state.tr_resource_category = tr_resource_category), 
         settr_resources :(state,tr_resources ) => (state.tr_resources = tr_resources), 
        settr_resource :(state,tr_resource ) => (state.tr_resource = tr_resource), 
    },

    actions : {
        // ==================================== Categories ==================================
        async create_tr_resource_category( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_tr_resource_category')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/tr_resource_categories/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settr_resource_categories', response.data.res)
                toast.success("Category Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_tr_resource_categories( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_tr_resource_categories')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/tr_resource_categories?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settr_resource_categories', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_tr_resource_category( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_tr_resource_category' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/tr_resource_categories/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settr_resource_category', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_tr_resource_category( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_tr_resource_category' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/tr_resource_categories/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settr_resource_categories', response.data.res)
                toast.success("Category Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_tr_resource_category_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_tr_resource_category_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/entrant/tr_resource_categories/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('settr_resource_categories', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Category Activated Successfully")
                        } else {
                            toast.success("Category Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_tr_resource_category( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_tr_resource_category' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/tr_resource_categories/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settr_resource_categories', response.data.res)
                toast.success("Category Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        // ========================================== Resources =========================================
        async create_tr_resource( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_tr_resource')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/tr_resources/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settr_resources', response.data.res)
                toast.success("Resource Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_tr_resources( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_tr_resources')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/tr_resources?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settr_resources', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_tr_resource( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_tr_resource' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/tr_resources/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settr_resource', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_tr_resource( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_tr_resource' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/tr_resources/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settr_resources', response.data.res)
                toast.success("Resource Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_tr_resource_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_tr_resource_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/entrant/tr_resources/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('settr_resources', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Resource Activated Successfully")
                        } else {
                            toast.success("Resource Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_tr_resource( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_tr_resource' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/tr_resources/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('settr_resources', response.data.res)
                toast.success("Resource Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}
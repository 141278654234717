export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        sub_topics :'', 
        sub_topic :'', 
    },
    
    getters : {
        sub_topics: (state )=> state.sub_topics,
        sub_topic: (state )=> state.sub_topic,
    },


    mutations : { 
        setsub_topics :(state,sub_topics ) => (state.sub_topics = sub_topics), 
        setsub_topic :(state,sub_topic ) => (state.sub_topic = sub_topic), 
    },

    actions : {

        async create_sub_topic( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_sub_topic')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/sub_topics/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsub_topics', response.data.res)
                toast.success("Sub Topic Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_sub_topics( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_sub_topics')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/sub_topics?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsub_topics', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_sub_topic( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_sub_topic' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/sub_topics/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsub_topic', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_sub_topic( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_sub_topic' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/sub_topics/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsub_topics', response.data.res)
                toast.success("Sub Topic Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_sub_topic_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_sub_topic_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/entrant/sub_topics/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setsub_topics', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Sub Topic Activated Successfully")
                        } else {
                            toast.success("Sub Topic Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_sub_topic( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_sub_topic' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/sub_topics/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsub_topics', response.data.res)
                toast.success("Sub Topic Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import './axios'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import moment from 'moment'
import '@/assets/app_css.css'
import { VueMultiImageUpload } from '@zakerxa/vue-multiple-image-upload';

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '7dc85893a1b1f0775552',
    cluster: 'mt1',
    encrypted: true
})

const app = createApp(App).component('QuillEditor', QuillEditor).component("vue-multi-image-upload", VueMultiImageUpload).use(store).use(router).use(Toast)

app.config.globalProperties.$filters = {
    timeAgo(date) {
    return moment(date).fromNow()
    },

    dateonly(date) {
        return moment(date).format("DD/MM/YYYY")
    },

    datetime(date){
        return moment(date).format("YYYY-MM-DD HH:mm:ss")
    },

    timeonly(date){
        return moment(date).format("HH:mm:ss")
    },

    remove_dash(value){
        if (!value) return ''
        return value.replace(/_/g, ' ') 
    },

    currency(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
}

app.mount('#app')

export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        impacts :'', 
        impact :'', 
    },
    
    getters : {
        impacts: (state )=> state.impacts,
        impact: (state )=> state.impact,
    },


    mutations : { 
        setimpacts :(state,impacts ) => (state.impacts = impacts), 
        setimpact :(state,impact ) => (state.impact = impact), 
    },

    actions : {

        async create_impact( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_impact')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/impacts/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setimpacts', response.data.res)
                toast.success("Impact Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_impacts( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_impacts')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/impacts?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setimpacts', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_impact( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_impact' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/impacts/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setimpact', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_impact( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_impact' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/impacts/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setimpacts', response.data.res)
                toast.success("Impact Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_impact_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_impact_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/entrant/impacts/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setimpacts', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Impact Activated Successfully")
                        } else {
                            toast.success("Impact Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_impact( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_impact' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/impacts/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setimpacts', response.data.res)
                toast.success("Impact Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}
export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        shop_categories :'', 
        shop_category :'', 
    },
    
    getters : {
        shop_categories: (state )=> state.shop_categories,
        shop_category: (state )=> state.shop_category,
    },


    mutations : { 
        setshop_categories :(state,shop_categories ) => (state.shop_categories = shop_categories), 
        setshop_category :(state,shop_category ) => (state.shop_category = shop_category),
    },

    actions : {
        // ==================================== Categories ==================================
        async create_shop_category( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_shop_category')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/shop_categories/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setshop_categories', response.data.res)
                toast.success("Category Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_shop_categories( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_shop_categories')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/shop_categories?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setshop_categories', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_shop_category( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_shop_category' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/shop_categories/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setshop_category', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_shop_category( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_shop_category' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/shop_categories/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setshop_categories', response.data.res)
                toast.success("Category Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_shop_category_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_shop_category_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/entrant/shop_categories/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setshop_categories', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Category Activated Successfully")
                        } else {
                            toast.success("Category Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_shop_category( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_shop_category' + id)
            return new Promise((resolve, reject) => {
            axios.post("/entrant/shop_categories/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setshop_categories', response.data.res)
                toast.success("Category Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}
let auth_routes = [{
    path: "/auth",
    name: 'auth',
    props: true,
    component: () => import("@/views/auth/main.vue"),
    children: [
      // ============================================== Dashboard ============================================
      {
        path: "login",
        name: 'login',
        props: true,
        component: () => import("@/views/auth/login.vue"),
        meta: {
          title: 'Sign In'
        },
      },

      {
        path: "register",
        name: 'register',
        props: true,
        component: () => import("@/views/auth/register.vue"),
        meta: {
          title: 'Sign Up'
        },
      },

      {
        path: "forgot_password",
        name: 'forgot_password',
        props: true,
        component: () => import("@/views/auth/forgot_pwd.vue"),
        meta: {
          title: 'Forgot Password'
        },
      },

      {
        path: "verify_code",
        name: 'verify_code',
        props: true,
        component: () => import("@/views/auth/verify_code.vue"),
        meta: {
          title: 'Verify Code'
        },
      },


      {
        path: "verify_account",
        name: 'verify_account',
        props: true,
        component: () => import("@/views/auth/verify_account.vue"),
        meta: {
          title: 'Verify Account'
        },
      },
    ]
}];

export default auth_routes;
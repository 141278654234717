export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        notification :'',
        notifications :'', 
    },
    
    getters : {
        notification: (state )=> state.notification,
        notifications: (state )=> state.notifications,
    },


    mutations : { 
        setnotification :(state,notification ) => (state.notification = notification),
        setnotifications :(state,notifications ) => (state.notifications = notifications),  
    },

    actions : {
        async get_user_notifications( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_user_notifications')
            return new Promise((resolve, reject) => {
            axios.post("/global/notifications?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setnotifications', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async mark_as_read( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'mark_as_read')
            return new Promise((resolve, reject) => {
            axios.post("/global/notifications/mark_as_read/"+id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setnotifications', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async mark_all_as_read( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'mark_all_as_read')
            return new Promise((resolve, reject) => {
            axios.post("/global/notifications/mark_all_as_read/", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setnotifications', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },

    
}


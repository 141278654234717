export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        counts :'',
    },
    
    getters : {
        counts: (state )=> state.counts,
    },


    mutations : {
        setcounts :(state,counts ) => (state.counts = counts), 
    },

    actions: {
        async get_counts( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_counts')
            return new Promise((resolve, reject) => {
            axios.post("/entrant/statistics/counts", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcounts', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },

    
}
